<template>
	<div class="page_option">
		<vs-row>
			<vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="4" vs-xs="12">
				<div class="box">
					<h4>Utilisateur</h4>
					<hr/>
					<vs-button color="primary" type="filled" @click="toPageMember">Mon compte</vs-button><br/>
					<vs-button color="primary" type="filled" @click="toPage('/admin/option/login')">Identifiant</vs-button><br/>
					<vs-button color="primary" type="filled" @click="toPage('/admin/option/equipe')" v-if="admin">Equipe(s)</vs-button><br/>
					<vs-button color="primary" type="filled" @click="toPage('/admin/option/rgpd')" v-if="admin"  >RGPD</vs-button><br/>
				</div>
			</vs-col>
			<vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="4" vs-xs="12">
				<div class="box" v-if="admin">
					<h4>Etablissement</h4>
					<hr/>
					<vs-button color="primary" type="filled" @click="toPage('/admin/option/structure')">Structure</vs-button><br/>
					<vs-button color="primary" type="filled" @click="toPage('/admin/option/planning')">Planning</vs-button><br/>
					<vs-button color="primary" type="filled" @click="toPage('/admin/option/fermeture')">Fermeture</vs-button><br/>
					<vs-button color="primary" type="filled" disabled >-</vs-button><br/>
				</div>
			</vs-col>
			<vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="4" vs-xs="12">
				<div class="box" v-if="admin">
					<h4>Autres</h4>
					<hr/>
					<vs-button color="primary" type="filled" @click="toPage('/admin/option/terminal')">Terminaux et attestations</vs-button><br/>
					<vs-button color="primary" type="filled" @click="toPage('/admin/option/cgv')">CGV</vs-button><br/>
					<vs-button color="primary" type="filled" @click="toPage('/admin/option/essaipro')">Provenance séances</vs-button><br/>
					<vs-button color="primary" type="filled" @click="toPage('/admin/option/declaration')">Déclaration</vs-button><br/>
				</div>
			</vs-col>
		</vs-row>
		<hr/>
		Remplissage memoire navigateur: <b>{{ getPerfPourcent() }} %</b><br/>
		RAM utilisé: <b>{{ getRamUsedMo() }} Mo</b>
	</div>
</template>

<style lang="scss">
.page_option{
	.box{
		padding:20px;
		width:100%;
		min-height:200px;
	}
	button{
		width:100%;
		margin-top:10px;
	}
}
</style>



<script>
import Member from '@/database/models/member'


export default {
    data(){
        return{
			admin:false,
        }
    },
	mounted:function()
	{
        this.RoleAdmin();
	},
    methods:{

		toPageMember()
		{
			this.$router.push('/admin/member/'+ this.$srvApi.getLoginUserUid() ).catch(() => {});
		},
		toPage( url )
		{
			this.$router.push(url ).catch(() => {});
		},
        RoleAdmin()
        {
            this.admin = false;
                  //Chargement info user
            let uid = this.$srvApi.getLoginUserUid();
            Member.getMemory(uid, (mbr)=>
            {
                this.admin = mbr.type.toLowerCase() == "a";
            });
        },












		//Remplissage mémoire
		getPerfPourcent()
		{
			if( window.performance.memory == undefined )
				return '?';
			let perf = window.performance.memory;
			return ((perf.usedJSHeapSize/perf.jsHeapSizeLimit)*100).toFixed(2);
		},
		getRamUsedKo()
		{
			if( window.performance.memory == undefined )
				return '?';

			let perf = window.performance.memory;
			return (perf.usedJSHeapSize/1024).toFixed(2);
		},
		getRamUsedMo()
		{
			if( window.performance.memory == undefined )
				return '?';

			let perf = window.performance.memory;
			return ((perf.usedJSHeapSize/1024)/1024).toFixed(2);
		}
    }
};

</script>